import { action, json } from "@solidjs/router";
import { _submitWhitelistNumber } from "../roma-api/sms";
import { getSMSNumbers } from "./";
import { type SMSSubmissionPayload } from "../roma-api/sms/types";

export const addWhitelistNumberAction = action(
  async (payload: SMSSubmissionPayload) => {
    const response = await _submitWhitelistNumber(payload);

    return json(response, { revalidate: getSMSNumbers.key });
  }
);
