import { action, json } from "@solidjs/router";
import { _putWhitelistNumber } from "../roma-api/sms";
import { getSMSNumbers } from "./";
import { type SMSSubmissionPayload } from "../roma-api/sms/types";

export const updateWhitelistNumberAction = action(
  async (payload: SMSSubmissionPayload, id: string) => {
    const response = await _putWhitelistNumber(payload, id);

    return json(response, { revalidate: getSMSNumbers.key });
  }
);
