import { type SMSList } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _listNumbers = async (): Promise<SMSList> => {
  // await simulateApiError({ path: "/sms" });

  const data = await fetchAPI2<SMSList>("/sms");
  return data;
};
