import { type SMSSubmissionPayload } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _putWhitelistNumber = async (
  payload: SMSSubmissionPayload,
  id: string
) => {

  // await simulateApiError({path: `/sms/${id}`});

  const data = await fetchAPI2<unknown, SMSSubmissionPayload>(`/sms/${id}`, {
    method: "PUT",
    body: payload,
  });

  // TODO Error handling / Type of response (currently empty 201, future possible response body)

  return data;
};
