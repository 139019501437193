import { type SMSSubmissionPayload } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _submitWhitelistNumber = async (payload: SMSSubmissionPayload) => {

  // await simulateApiError({path: "/sms"});

  const data = await fetchAPI2<{ ID: string }, SMSSubmissionPayload>("/sms", {
    method: "POST",
    body: payload,
  });

  return data;
};
