import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _removeWhitelistNumber = async (id: string) => {
  // await simulateApiError({path: `/sms/${id}`});

  const data = await fetchAPI2(`/sms/${id}`, {
    method: "DELETE",
  });

  // TODO Error handling / Response type (currently 201 empty..eventually someresponse?)

  return data;
};
